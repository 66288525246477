import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  getFieldsConfiguration,
  getPhoneCommunicationTypes,
  getTelephoneRegionPrefixesEntities,
} from 'store/settings/selectors';

import { PhonesFormValues } from '../../../../../types';

type PhoneFormatter = (phone?: PhonesFormValues) => string | undefined;

export const usePhoneFormatter = (): PhoneFormatter => {
  const phoneTypes = useSelector(getPhoneCommunicationTypes);
  const prefixes = useSelector(getTelephoneRegionPrefixesEntities);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const phoneFieldsConfiguration = fieldsConfiguration?.phoneObjectCollection;

  const stringifyPhone = useCallback(
    (phone?: PhonesFormValues): string | undefined => {
      if (!phone) return;

      const phoneNumber = [
        prefixes?.find((option) => option.code === phone?.countryCode)?.prefix,
        phone?.phoneNumber,
      ]
        .filter(isDefined)
        .join(' ');

      return [
        phoneFieldsConfiguration?.typeField?.isVisible
          ? phoneTypes?.find((option) => option.id === phone?.type)?.description
          : undefined,
        phoneFieldsConfiguration?.detailsField?.isVisible
          ? phoneNumber
          : undefined,
      ]
        .filter(isDefined)
        .join(', ');
    },
    [phoneTypes, prefixes, phoneFieldsConfiguration]
  );

  return stringifyPhone;
};
