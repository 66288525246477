import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  getFieldsConfiguration,
  getMobileCommunicationTypes,
  getTelephoneRegionPrefixesEntities,
} from 'store/settings/selectors';

import { PhonesFormValues } from '../../../../../types';

type MobileFormatter = (mobile?: PhonesFormValues) => string | undefined;

export const useMobileFormatter = (): MobileFormatter => {
  const mobileTypes = useSelector(getMobileCommunicationTypes);
  const prefixes = useSelector(getTelephoneRegionPrefixesEntities);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const mobileFieldsConfiguration = fieldsConfiguration?.mobileObjectCollection;

  const stringifyMobile = useCallback(
    (mobile?: PhonesFormValues): string | undefined => {
      if (!mobile) return;

      const phoneNumber = [
        prefixes?.find((option) => option.code === mobile?.countryCode)?.prefix,
        mobile?.phoneNumber,
      ]
        .filter(isDefined)
        .join(' ');

      return [
        mobileFieldsConfiguration?.typeField?.isVisible
          ? mobileTypes?.find((option) => option.id === mobile?.type)
              ?.description
          : undefined,
        mobileFieldsConfiguration?.detailsField?.isVisible
          ? phoneNumber
          : undefined,
      ]
        .filter(isDefined)
        .join(', ');
    },
    [mobileTypes, prefixes, mobileFieldsConfiguration]
  );

  return stringifyMobile;
};
