import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskTelephoneRegionPrefixes,
  KioskUpdateCommunicationChannels,
  KioskUpdateCommunicationDetails,
} from 'api/KioskApi/entries';
import { PhonesFormValues } from 'views/RegistrationCardEditPersonal/types';

import { prepareAddUpdateRemovePayload } from './prepareAddUpdateRemovePayload';

const mapSinglePhoneToUpdatePayload = (
  phoneFormData: PhonesFormValues,
  telephoneRegionPrefixes?: KioskTelephoneRegionPrefixes[]
): KioskUpdateCommunicationDetails => {
  const phonePrefix = telephoneRegionPrefixes?.find(
    (item) => item.code === phoneFormData?.countryCode
  )?.prefix;

  return {
    id: phoneFormData.id,
    typeId: phoneFormData.type,
    details: [phonePrefix, phoneFormData.phoneNumber]
      .filter(isDefined)
      .join(' '),
    isPrimary: phoneFormData.isPrimary || false,
  };
};

export const preparePhonesUpdatePayload = (
  formData?: PhonesFormValues[],
  initialValues?: PhonesFormValues[],
  telephoneRegionPrefixes?: KioskTelephoneRegionPrefixes[]
): KioskUpdateCommunicationChannels | undefined => {
  const payload = prepareAddUpdateRemovePayload(
    (phoneFormData: PhonesFormValues) =>
      mapSinglePhoneToUpdatePayload(phoneFormData, telephoneRegionPrefixes),
    formData,
    initialValues
  );

  return Object.keys(payload).length ? payload : undefined;
};
